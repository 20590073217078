.logo-icon {
  margin-top: 105px;
  margin-bottom: 50px;
}

.btn-login {
  margin-top: 60px;
  color: '#6B8FED';
  border-color: '#6B8FED';
  font-weight: 'bold';
  width: 117px;
}

.title {
  font-size: 14px;
  -webkit-box-shadow: 0 3px 5px -3px #000;
  -moz-box-shadow: 0 3px 5px -3px #000;
  box-shadow: 0 3px 5px -3px #000;
  padding-bottom: 2px;
  padding-left: 30px;
}

.input {
  width: 410px;
  justify-content: flex-end;
}

.item-input {
  display: flex;
  width: 560px;
}

.scrollbar {
  overflow-x: auto;
  padding-bottom: 10px;
}
